import React, { useState } from 'react';
import './PhoneOtpComponent.css'; // Import the stylesheet
import axios from 'axios'

const PhoneOtpComponent = () => {
  const [step, setStep] = useState(1); // Step 1: Phone Input, Step 2: OTP Input
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp1, setOtp1] = useState(['', '', '', '', '', '']); // Six separate inputs
  const [errorMessage, setErrorMessage] = useState('');

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setErrorMessage('');
  };



  const validatePhoneNumber = () => /^[0-9]{10}$/.test(phoneNumber);

  const handleOtpChange = (value, index) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp1];
      newOtp[index] = value;
      setOtp1(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
    setErrorMessage('');
  };

  const validateOtp = () => otp1.every((digit) => /^[0-9]$/.test(digit));
  const otp = otp1.join('');
  /* const handleSendOtp = () => {
    if (validatePhoneNumber()) {
      setStep(2);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid 10-digit phone number.');
    }
  }; */
  /*** included part below */
  const handleSendOtp = async () => {
    if (validatePhoneNumber()) {
    //     alert(phoneNumber)
      try {
        // Replace 'your_api_endpoint' and 'your_api_key' with actual values+
        const response = await axios.post("http://localhost:5001/generate-otp", { phoneNumber });
        // const response = await axios.post("http://localhost:5000/generate-otp", { phoneNumber });


         
        if (response.status === 200) {
          setStep(2);
          setErrorMessage('OTP sent successfully. Please check your phone.');
        } else {
          setErrorMessage('Failed to send OTP. Please try again later.');
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again later.');
        console.error(error);
      }
    } else {
      setErrorMessage('Please enter a valid 10-digit phone number.');
    }
  };
  const handleVerifyOtp = async () => {
   
    if (validateOtp()) {
      try {
    //    alert(phoneNumber);
    //   alert(otp);
       // otp = otp.join('');
        //const response = await axios.post("config.verificationEndpoint", { phoneNumber, otp });
        const response = await axios.post("http://localhost:5001/verify-otp", { phoneNumber, otp });
    //    alert(response.status);
        if (response.status === 200) {
          alert('OTP Verified Successfully!');
          setErrorMessage('');
        } else {
          setErrorMessage('OTP verification failed. Please try again.'); // Consider a more specific message based on the API response
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again later.');
        console.error(error);
      }
    } else {
      setErrorMessage('Please enter a valid 6-digit OTP.');
    }
  };
  /*** till here  */

  /* const handleVerifyOtp = () => {
    if (validateOtp()) {
      alert('OTP Verified Successfully!');
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid 6-digit OTP.');
    }
  }; */



  return (
    <div className="otp-container">
      {step === 1 && (
        <div className="otp-row otp-left-align">
          <label className="otp-label">Phone:</label>
          <input
            type="text"
            placeholder="Phone number"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            className="otp-input"
          />
          <button onClick={handleSendOtp} className="otp-button">
            Send OTP
          </button>
        </div>
      )}

      {step === 2 && (
        <div className="otp-row otp-left-align">
          <label className="otp-label">Phone:</label>
          <span className="otp-display">{phoneNumber}</span>
          <div className="otp-box">
            {otp1.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                maxLength="1"
                className="otp-digit"
              />
            ))}
          </div>
          <button onClick={handleVerifyOtp} className="otp-button">
            Verify OTP
          </button>
        </div>
      )}

      {errorMessage && <p className="otp-error">{errorMessage}</p>}
    </div>
  );
};

export default PhoneOtpComponent;
