import React, { useState } from 'react';
import PhoneOtpComponent from './components/PhoneOtpComponent';
import './App.css';

function App() {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="App">
      <div className={`product-info ${isExpanded ? 'expanded' : ''}`}>
        <h2 className="product-title">Product Name</h2>
        <div className="product-description">
          <p>
            This is a sample product description. It contains details about the product. It is long enough to
            demonstrate truncation and responsiveness. Here is additional content to showcase the scroll behavior in
            responsive mode. More and more text will go here to ensure that the scrollable feature is evident. Even
            more text, because why not?
          </p>
          <p>Additional content for testing the vertical scroll bar behavior in the product description section.</p>
          <p>Scroll down to see more!</p>
        </div>
        <button className="more-link" onClick={toggleExpand}>
          {isExpanded ? 'Show Less' : '...more...'}
        </button>
        <PhoneOtpComponent/>
      </div>
        
    </div>
  );
}

export default App;
